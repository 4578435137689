.featuredSellersSection {
    margin-bottom: 40px;
    padding: 0 20px; /* Add some padding to the section */
  }
  
  .featuredSellersTitle {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .sellersContainer {
    display: flex;
    flex-direction: column;
  }
  
  .sellerCard {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;
    position: relative;
    width: 100%; /* Full width on mobile */
  }
  
  .sellerInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .sellerName {
    font-size: 18px;
    font-weight: bold;
  }
  
  .messageButton {
    background-color: #4a4a4a;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .carouselContainer {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .carouselWrapper {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
    flex-grow: 1;
  }
  
  .carouselWrapper::-webkit-scrollbar {
    display: none;
  }
  
  .carouselTrack {
    display: flex;
    transition: transform 0.3s ease;
  }
  
  .carouselItem {
    flex-shrink: 0;
    padding: 0 10px;
  }
  
  .carouselButton {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  
  .carouselButtonPrev {
    margin-right: 10px;
  }
  
  .carouselButtonNext {
    margin-left: 10px;
  }
  
  @media (min-width: 769px) {
    .sellersContainer {
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap; /* Allow wrapping if needed */
    }
  
    .sellerCard {
      flex: 0 1 calc(33.333% - 20px); /* Use calc to account for margins */
      margin-right: 20px;
      margin-bottom: 20px;
      max-width: calc(33.333% - 20px); /* Ensure cards don't grow too large */
    }
  
    .sellerCard:nth-child(3n) {
      margin-right: 0; /* Remove right margin for every third card */
    }
  }
  
  @media (min-width: 1200px) {
    .featuredSellersSection {
      padding: 0 40px; /* Increase padding for larger screens */
    }
  
    .sellerCard {
      flex: 0 1 calc(33.333% - 40px); /* Increase space between cards */
      max-width: calc(33.333% - 40px);
      margin-right: 40px;
    }
  }